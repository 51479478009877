import React, { useState } from 'react';
//import logo from './logo.svg';
import '../App.css';
import {  MDBInput,  MDBBtn,  MDBCheckbox,  MDBRow,  MDBCol,  MDBIcon,  MDBInputGroup,  MDBNavbar,  MDBContainer
} from 'mdb-react-ui-kit';
import { useNavigate} from 'react-router-dom'
import Axios from 'axios'

function Welcome() {
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

  sessionStorage.removeItem('loggedIn');
  localStorage.removeItem('loggedIn');
  localStorage.removeItem('userType');
  localStorage.removeItem('userID');
  localStorage.removeItem('name');
  localStorage.removeItem('position');


  const [user, setUser] = useState('')
  const [pwd, setPwd] = useState('')

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = async e => {
    e.preventDefault();
    //navigate('/ApplicationForm')
    //alert('Uname = '+user+ ' pWD = '+pwd)
   setHandleSubmitBtn('Loading')
    
    var formData = new FormData(this)
    formData.append('apiType', 'login')
    formData.append('username', user)
    formData.append('password', pwd)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/login.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtn('')
      //alert(response.data)
      if(response.data.loggedIn === 'Yes'){
      setUser('')
      setPwd('')
      //alert(response.data)
      sessionStorage.setItem('loggedIn', JSON.stringify(response.data.loggedIn))
      localStorage.setItem('loggedIn', JSON.stringify(response.data.loggedIn))
      localStorage.setItem('userType', JSON.stringify(response.data.userType))
      localStorage.setItem('userID', JSON.stringify(response.data.userID))
      localStorage.setItem('name', JSON.stringify(response.data.name))
      localStorage.setItem('position', JSON.stringify(response.data.position))

      navigate('/Home')
    }
      else{      
      alert('Error : Failed To Login')
      //alert(response.data.name)
    }
    }).catch((err) => alert("Connection Error : Check Internet Connectivity")
    );
 
};

const handleSZCAlignmentSession = async e => {
  navigate('/SZCAlignmentSession')
};
  
  return (
    <>
   <div className="row">
  <div className="col-11">
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top scrolling-navbar col-11">
        <div className="container">
          <a className="navbar-brand" href="#"><strong>SZBU</strong></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-7"
            aria-controls="navbarSupportedContent-7" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent-7">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a className="nav-link"  href="ApplicationForm" >Register</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="view jarallax" data-jarallax='{"speed": 0.2}' style={{
        backgroundImage: `url(${require('./img/szbu.jpg')})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
      }}>
        <div className="mask rgba-black-light">
          <div className="container h-100 d-flex justify-content-center align-items-center">
            <div className="row pt-5 mt-3">
              <div className="col-md-12">
                <div className="text-center">
                  <h1 className="h1-reponsive white-text text-uppercase font-weight-bold mb-3 wow fadeInDown" data-wow-delay="0.3s"><strong>
                    SOUTHERN ZAMBIA UNION CONFERENCE
                    </strong></h1>
                  <hr className="hr-light mt-4 wow fadeInDown" data-wow-delay="0.4s" />
                  <h5 className="text-uppercase mb-5 white-text wow fadeInDown" data-wow-delay="0.4s"><strong>
                    2024 Music Camp Meeting - Njolwe Camp Site</strong></h5>
                  <a href="ApplicationForm" className="btn btn-outline-white wow fadeInDown" data-wow-delay="0.4s">Register</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    {/* Main Navigation */}

    {/* Footer */}
    <footer className="page-footer pt-0 mt-5">

      {/* Copyright */}
      <div className="footer-copyright py-3 text-center" style={{ backgroundColor: 'black', color: 'white' }}>
        <p>
          Southern Zambia Union Conference.
        </p>
        <div className="container-fluid font-small">
          Powered : <a href="https://www.kanchtech.com" target="_blank" style={{ color: 'yellow' }}> Kanch Innovative Technologies</a>
        </div>
      </div>
      {/* Copyright */}

    </footer>
  </div>

  <section className="col-1" style={{ backgroundColor: 'rgb(15, 82, 126)' }}>
    <center>
    <img src={require('./img/sda.png')} width="100%" alt="SDA Logo" />
    </center>
  </section>
</div>

    </>
  );
}

export default Welcome;
