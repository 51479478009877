import React, {useState, useEffect} from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
  MDBIcon,
  MDBFile
}
from 'mdb-react-ui-kit';
//import Header from './Header';
import Axios from 'axios'
import { useNavigate, Navigate, Routes } from 'react-router-dom'


function Admin() {
    
    localStorage.removeItem("userID");
    localStorage.removeItem("position");
    localStorage.removeItem("office");
    localStorage.removeItem("entity");
    localStorage.removeItem("committee");
    localStorage.removeItem("name");
    sessionStorage.removeItem('loggedIn');

    let navigate = useNavigate()
    let source = localStorage.getItem('userID')
    source = JSON.parse(source)

  const server_app_api_php = window.SERVER_APP_API_PHP

 

   const[username, setUsername]= useState('')
	const[password, setPassword]= useState('')
	
  const handleSubmit = async e => {
    //alert('Username ='+username+' Password = '+ password)
    
    var formData = new FormData(this)

    formData.append('apiType', 'login')
    formData.append('username', username)
    formData.append('password', password)
   Axios({
      method: 'post',
      url: `${server_app_api_php}/SZBUmusicCampMeeting.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
        if(response.data === 'User Does Not Exists'){
        alert(response.data)
        }
        else{
            //alert(response.data)
        setUsername('')
        setPassword('')

        localStorage.setItem('userID', JSON.stringify(response.data.usersID))
        localStorage.setItem('position', JSON.stringify(response.data.position))
        localStorage.setItem('office', JSON.stringify(response.data.office))
        localStorage.setItem('entity', JSON.stringify(response.data.entity))
        localStorage.setItem('committee', JSON.stringify(response.data.committee))
        localStorage.setItem('name', JSON.stringify(response.data.name))
        sessionStorage.setItem('loggedIn', JSON.stringify('Yes'))
       navigate('/Dashboard')
        }
      })
      .catch(function (response) {
        //handle error
        alert("Error: Check Your Internet Connection")
      })
   
  }


  return (
    <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>

 

      <MDBRow>

      <a className="navbar-brand blue-text" href="."> <span class="fa fa-home"></span> <strong>Home</strong></a>
          

        <MDBCol md='5' className='text-center text-md-start d-flex flex-column justify-content-center'>

          <h3 className="my-5 display-6 fw-bold ls-tight px-1" style={{color: 'hsl(218, 81%, 95%)'}}>            
            <span style={{color: 'green'}}>LOGIN HERE</span>
          </h3>

          <p className='px-3 MDBColor primary' >
          Offcial Use Only. <br />
          No Unauthorized Access To This Page.
          </p>

        </MDBCol>

        <MDBCol md='7' className='position-relative'>

          <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
          <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

          

          <MDBCard className='my-5 bg-glass'>
            <MDBCardBody className='p-5'>
          
          <MDBRow>
          <MDBCol className='col-12 col-lg-6'>
            <div class="md-form md-outline">
                <input type="text" class="form-control"  onChange={e => setUsername(e.target.value)} value={username} />
                <label for="f2" class="">Username <span class="red-text">*</span></label>
                </div>
                </MDBCol>


                <MDBCol className='col-12 col-lg-6'>
            <div class="md-form md-outline">
                <input type="password" class="form-control"  onChange={e => setPassword(e.target.value)} value={password} />
                <label for="f2" class="">Password <span class="red-text">*</span></label>
                </div>
                </MDBCol>

        </MDBRow>
             
              <MDBBtn className='w-100 mb-0' size='md'
              onClick={handleSubmit} disabled={
                username === '' || password === ''                   
                }>
                Send
                </MDBBtn>

                <MDBRow>
        <MDBCol class="12">
        <span class="fa fa-star red-text 3x text-left mt-5"></span> -: Should Not Be Empty
        </MDBCol>
        </MDBRow>

             </MDBCardBody>
          </MDBCard>

        </MDBCol>

      </MDBRow>

    </MDBContainer>
  );
}

export default Admin;