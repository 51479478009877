import React, {useState, useEffect} from 'react';
import { MDBDataTable } from 'mdbreact';
import Axios from 'axios'
import Header from './Header';

const ViewUsers = ({ data }) => {
    const server_app_api_php = window.SERVER_APP_API_PHP
    
  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
    const [rows, setDBtableView] = useState([])

  // Define columns
  const columns = [
    {
      label: 'SN',
      field: 'sn',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Name',
      field: 'name',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Username',
      field: 'username',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Position',
      field: 'position',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Entity',
      field: 'entity',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Committee',
      field: 'committee',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Office',
      field: 'office',
      sort: 'asc',
      width: 150
    }
  ];

    // Construct the MDBDataTable object
  const dataTable = {
    columns: columns,
    rows: rows
  };

  
	
  useEffect(() => {
setHandleSubmitBtn('Loading');
var formData = new FormData(this)
formData.append('apiType', 'ViewUsers')
//formData.append('id', source)
Axios({
  method: 'post',
  url: `${server_app_api_php}/SZBUmusicCampMeeting.php`,
  data: formData,
  headers: { 'Content-Type': 'multipart/form-data' }
})
  .then(function (response) {
    //handle success
    setHandleSubmitBtn('');
   setDBtableView(response.data)
   //alert(response.data)
  })
  .catch(function (response) {
    //handle error
    alert("Error: Check Your Internet Connection")
    setHandleSubmitBtn('');
  })

}, [])

  return (
    <>
    <Header />
 

    <div hidden={handleSubmitBtn ===''}>
    <div id='overlayer'></div>
    <div class="loader d-flex justify-content-center">
  <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
      <span class='visually-hidden'>Loading...</span>
    </div>
  </div>
  </div>

  <div class="container mt-5">
    <section class="mt-5">
        <hr class="mt-5"/>
    <MDBDataTable
      striped
      bordered
      small
      data={dataTable}
    />
</section>
</div>
    </>
  );
};

export default ViewUsers;
