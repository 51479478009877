import React, {useState, useEffect} from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
  MDBIcon,
  MDBFile,
  MDBTable, MDBTableHead, MDBTableBody
}
from 'mdb-react-ui-kit';
import { MDBDataTable } from "mdbreact";
import Axios from 'axios'
import { useNavigate, Navigate, Routes } from 'react-router-dom'

function Header() {
    let navigate = useNavigate()
  //const server_app_api_php = window.SERVER_APP_API_PHP

  let loggedIn = sessionStorage.getItem('loggedIn')
  loggedIn = JSON.parse(loggedIn)
  //alert(loggedIn)
  if (loggedIn === 'No' || loggedIn ==='' || loggedIn === undefined || loggedIn === null) {
    navigate('/Admin')    
  }

  /*
          localStorage.setItem('userID', JSON.stringify(response.data.usersID))
        localStorage.setItem('position', JSON.stringify(response.data.position))
        localStorage.setItem('office', JSON.stringify(response.data.office))
        localStorage.setItem('entity', JSON.stringify(response.data.entity))
        localStorage.setItem('committee', JSON.stringify(response.data.committee))
        localStorage.setItem('name', JSON.stringify(response.data.name))
  */
  let userPosition = localStorage.getItem('position')
  userPosition = JSON.parse(userPosition)
  //alert(userPosition)

  let userName = localStorage.getItem('name')
  userName = JSON.parse(userName)

  return (
    <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>

<nav className="navbar navbar-expand-lg navbar-dark blue fixed-top scrolling-navbar">
        <div className="container">
          <a className="navbar-brand white-text" ><strong>SINGERS DATABASE</strong></a>
          <button className="navbar-toggler btn-primary" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-7"
            aria-controls="navbarSupportedContent-7" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon blue-text"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent-7">
            <ul className="navbar-nav mr-auto">
            <li className="nav-item">
                <a className="nav-link" href="Dashboard">Dashboard</a>
              </li>

              {userPosition === 'Admin' ? (
                        <>
              <li className="nav-item">
                <a className="nav-link" href="RegisterUsers">Register Users</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="ViewUsers">View Users</a>
              </li>
              </>
                      ) : (
                        <></>
                      )}

              <li className="nav-item">
                <a className="nav-link" href="Admin">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </MDBContainer>
  );
}

export default Header;