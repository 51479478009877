import logo from './logo.svg'
import './App.css'
import { Routes, Route } from 'react-router-dom'

import Welcome from './pages/Welcome'
import ApplicationForm from './pages/ApplicationForm'
import Admin from './pages/Admin'
import Dashboard from './pages/Dashboard'
import RegisterUsers from './pages/RegisterUsers'
import ViewUsers from './pages/ViewUsers'
import Missing from './404/Missing'


function App () {
  return (
    <Routes>
      <Route>
        {/* Public Routes*/}
        {/* We want To Protect These Routes*/}
        <Route path='/' element={<Welcome />} />
        <Route path='/ApplicationForm' element={<ApplicationForm />} />
        <Route path='/Admin' element={<Admin />} />
        <Route path='/Dashboard' element={<Dashboard />} />
        <Route path='/RegisterUsers' element={<RegisterUsers />} />
        <Route path='/ViewUsers' element={<ViewUsers />} />
        {/* catch all*/}
        <Route path='*' element={<Missing />} />
      </Route>
    </Routes>
  )
}

export default App
