import React, {useState, useEffect} from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
  MDBIcon,
  MDBFile,
  MDBTable, MDBTableHead, MDBTableBody
}
from 'mdb-react-ui-kit';
import { MDBDataTable } from "mdbreact";
import Header from './Header';
import Axios from 'axios'

function Dashboard() {

  const server_app_api_php = window.SERVER_APP_API_PHP

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  const [rows, setDBtableView] = useState([])
    //const source = 1;

  
	
    useEffect(() => {
        setHandleSubmitBtn('Loading');
    var formData = new FormData(this)

    formData.append('apiType', 'ViewCampers')
    //formData.append('id', source)
   Axios({
      method: 'post',
      url: `${server_app_api_php}/SZBUmusicCampMeeting.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
       //alert(response.data)
        setHandleSubmitBtn('');
       setDBtableView(response.data)
       //alert(response.data)
      })
      .catch(function (response) {
        //handle error
        alert("Error: Check Your Internet Connection")
        setHandleSubmitBtn('');
      })
   
    }, [])


  let data = {
    columns: [
        {
            label: 'SN',
            field: 'sn',
            width: 150
        },
        {
            label: 'First Name',
            field: 'firstName',
            width: 150
        },
        {
            label: 'Last Name',
            field: 'lastName',
            width: 150
        },
        {
            label: 'Entity',
            field: 'entity',
            width: 150
        },
        {
            label: 'Phone Number',
            field: 'phoneNumber',
            width: 150
        },
        {
            label: 'Email',
            field: 'email',
            width: 150
        },
        {
            label: 'Gender',
            field: 'sex',
            width: 150
        },
        {
            label: 'Singing Group',
            field: 'choir',
            width: 150
        },
    ],
    rows: rows  
  };

  rows.map( (item,index) => {
    item.sn = (
<div>
    {index +1}
</div>
    );
  });

  return (
    <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>

<Header />

      <MDBRow>

      <div hidden={handleSubmitBtn ===''}>
    <div id='overlayer'></div>
    <div class="loader d-flex justify-content-center">
  <div class="spinner-grow text-primary" style={{width: '10rem', height: '10rem'}} role="status">
      <span class='visually-hidden'>Loading...</span>
    </div>
  </div>
  </div>

  

        <MDBCol md='12' className='position-relative text-center text-md-start d-flex flex-column justify-content-center'>

          <MDBCard className='my-5 bg-glass'>
            <MDBCardBody className='p-5'>
     
            <MDBDataTable 
            striped 
            bordered 
            hover 
            small 
            data={data} 
            selectRows={true}
            entriesOptions={[5, 10, 20, 50,100,500,1000, 2000, 5000]}
            entries={5}
            />

             </MDBCardBody>
          </MDBCard>

        </MDBCol>

      </MDBRow>

    </MDBContainer>
  );
}

export default Dashboard;