import { useState, useEffect } from 'react'
//import React, { useState } from 'react';
//import logo from './logo.svg';

import { useNavigate} from 'react-router-dom'
import Axios from 'axios'
import {
    MDBInput,
    MDBInputGroup,
    MDBBtn,
    MDBCheckbox,
    MDBValidation,
    MDBValidationItem, MDBCol, MDBRow, MDBRadio, MDBBtnGroup
  } from 'mdb-react-ui-kit';

function ApplicationForm() {
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

  const PHONE_REGEX =  /^\s*(?:\?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/ ;
  //const PHONE_REGEX =  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
  //const QUANTITY_REGEX = /^\d{1,9}$/
  const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/


 let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

    let [pop, setPop] = useState('')
    let [photo, setPhoto] = useState('')
   let [firstName, setFirstName] = useState('')
   let [lastName, setLastName] = useState('')
    let [phoneNumber, setPhoneNumber] = useState('')
    let [email, setEmail] = useState('')    
   let [sex, setsex] = useState('')
    let [entity, setEntity] = useState('')
    let [choir, setChoir] = useState('')

    let [verifyChoirLeaderPhone, setVerifyChoirLeaderPhone] = useState(false)
    const [validPhone, setValidPhone] = useState(false)
    const [phoneFocus, setPhoneFocus] = useState(false)
    const [validEmail, setValidEmail] = useState(false)
    const [emailFocus, setEmailFocus] = useState(false)

    const [entitiesDropDown, setEntitiesDropDown] = useState([])
    const [choirsDropDown, setChoirsDropDown] = useState([])
  

    useEffect(() => {
      const result = PHONE_REGEX.test(phoneNumber)
      setValidPhone(result)
      if(result === true){
       /* var formData = new FormData(this)
        formData.append('apiType', 'verifyChoirLeaderPhone')
        formData.append('phone', phoneNumber)
        Axios({
          method: 'post',
          url: `${server_app_api_php}/SZBUmusicCampMeeting.php`,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(response => {
          //alert(response.data);
          if(response.data === "Phone Exists"){
          setVerifyChoirLeaderPhone(true)
          //verifyChoirLeaderPhone = true;
          }
          else{
            setVerifyChoirLeaderPhone(false)
            //setPop(null)
            setPop('');
            document.getElementById('pop').value = '';
          }
          //alert(response.data);
        }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
        */
      }
      else{
        setVerifyChoirLeaderPhone(false)
        //setPop(null)
        setPop('');
        document.getElementById('pop').value = '';
      }
    }, [phoneNumber])
    
  
     useEffect(() => {
      const result = EMAIL_REGEX.test(email)
      setValidEmail(result)
    }, [email])

  const handleSubmit = async e => {
   setHandleSubmitBtn('Loading')    
    var formData = new FormData(this)
    formData.append('apiType', 'registration')
    formData.append('pop', pop)
    formData.append('photo', photo)
    formData.append('firstName', firstName)
    formData.append('lastName', lastName)
    formData.append('phoneNumber', phoneNumber)
    formData.append('email', email)
    formData.append('sex', sex)
    formData.append('entity', entity)
    formData.append('choir', choir)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/SZBUmusicCampMeeting.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtn('')
      alert(response.data)
    setFirstName('')
    setLastName('')
    setPhoneNumber('')
    setEmail('')    
    setsex('')
    setEntity('')
    setChoir('')
    setPop('');
    document.getElementById('pop').value = '';
    setPhoto('');
    document.getElementById('photo').value = '';
    
    }).catch((err) => alert("Connection Error : Check Internet Connectivity"),
    setHandleSubmitBtn('')
    );    
};

const savePop = e => {
    setPop(e.target.files[0])
  }
  const savePhoto = e => {
    setPhoto(e.target.files[0])
  }


  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'entitiesDropDown')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/SZBUmusicCampMeeting.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setEntitiesDropDown(response.data)
      //alert(response.data);
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  useEffect(() => {
    var formDataChoirs = new FormData(this)
    formDataChoirs.append('apiType', 'choirsDropDown')
    formDataChoirs.append('entitiesID', entity)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/SZBUmusicCampMeeting.php`,
      data: formDataChoirs,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      setChoirsDropDown(res.data)
      //alert(res.data);
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [entity])

  
  return (
    <>
   <div className="row">
  <div className="col-11">
    {/*
    <header>
        */}
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top scrolling-navbar col-11">
        <div className="container">
          <a className="navbar-brand blue-text" href="."><strong>Home</strong></a>
          <button className="navbar-toggler btn-primary" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-7"
            aria-controls="navbarSupportedContent-7" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon blue-text"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent-7">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a className="nav-link" href="#"></a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    
    {/* Main Navigation */}


    <section className='mt-5'>
          <div class="row">
  <div class="col-12 col-md-12 col-lg-12 col-xl-12">
      <div className='h-100 d-flex justify-content-center align-items-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5'>
              <div className='card'>
                <div className='card-body'>
               {/* <Navbar /> */}
               
               
               
                  <div className='row mt-1'>
                    <h5 className='font-weight-500 my-1'>
                      APPLICATION FORM
                    </h5>
                  </div>
                  <div class='text-center'>

    <hr class='mt-2' />   
                    <MDBRow className='row g-3' >        
      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setFirstName(e.target.value)}
        value={firstName}
          label='First name'
        />
      </MDBCol>
      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setLastName(e.target.value)}
          value={lastName}
          label='Last name'
        />
      </MDBCol>
      <MDBCol className='col-md-6 col-12' >
        <MDBInput
          id='validationCustom03'
          onChange={e => setEmail(e.target.value)}
          value={email}
          label='Email'
          onFocus={() => setEmailFocus(true)}
          onBlur={() => setEmailFocus(false)}
        />
        <p
      className='red-text'
          hidden={
              emailFocus === false || validEmail === true
          }
        >          
          4 to 24 characters. <br />
          Let It Be A Valid Email Address.
        </p>
      </MDBCol>
      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setPhoneNumber(e.target.value)}
          value={phoneNumber}
          label='Phone Number'
          onFocus={() => setPhoneFocus(true)}
          onBlur={() => setPhoneFocus(false)}
        />
         <p
                  className='red-text'
                  hidden={
                      phoneFocus === false || validPhone === true
                  }
                >
                  There should be 10 numerical values.
                </p>
      </MDBCol>

      {/*
      <MDBCol className='col-md-12 col-12 text-left' hidden={verifyChoirLeaderPhone != true}>
                */}
      <MDBCol className='col-md-12 col-12 text-left' hidden>
      <label>Attach Proof Of Payment</label>
        <MDBInput
        type='file'
          id='pop'
          onChange={savePop}
        />
      </MDBCol>
   
     <MDBCol className='col-md-6 col-12 text-left'>
        <label>Gender</label>
        <select
        class='custom-select d-block w-100'
          id='validationCustom01'
          onChange={e => setsex(e.target.value)}
        value={sex}
        >
            <option value=''>Choose Gender</option>
            <option>Male</option>
            <option>Female</option>
        </select>
      </MDBCol>
     
      <MDBCol className='col-md-6 col-12 text-left' hidden>
      <label>Attach Passport Size Photo</label>
        <MDBInput
        type='file'
          id='photo'
          onChange={savePhoto}
        />
      </MDBCol>

      <MDBCol className='col-md-6 col-12 text-left'>
        <label>Entity</label>
        <select
        class='custom-select d-block w-100'
          id='validationCustom01'
          onChange={e => setEntity(e.target.value)}
        value={entity}
        >
            <option value=''>Choose Entity</option>
            {entitiesDropDown.map(val => {
                          return (
                            <option value={val.entitiesID}>
                              {val.entity.toUpperCase()}
                            </option>
                          )
                        })}
        </select>
      </MDBCol>
            
     
      <MDBCol className='col-md-6 col-12 text-left' hidden>
        <label>Singing Group Name</label>
        <select
        class='custom-select d-block w-100'
          id='validationCustom01'
          onChange={e => setChoir(e.target.value)}
          value={choir}
        >
            <option value=''>Choose Singing Group Name</option>
           {
  choirsDropDown.map(val => {
    return (
      <option value={val.choirsID}>
        {val.choir.toUpperCase()}
      </option>
    )
  })
}
        </select>
      </MDBCol>
      </MDBRow>

<div class='row'>          
        <button onClick={handleSubmit} class='btn btn-primary col-12 mt-5'
        disabled={firstName === '' || lastName === '' || sex === '' || !validPhone || phoneNumber === '' ||
        entity === ''  || handleSubmitBtn === 'Loading'}
             >
           <div class="spinner-border" role="status" hidden = {handleSubmitBtn === ''}> </div>
          Submit</button>      
  </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>
      </div>
      </section>

     {/*  </header>

    Footer */}
    <footer className="page-footer pt-0 mt-0">

      {/* Copyright */}
      <div className="footer-copyright py-5 text-center" style={{ backgroundColor: 'black', color: 'white' }}>
        <p>
          Southern Zambia Union Conference.
        </p>
        <div className="container-fluid font-small">
          Powered : <a href="https://www.kanchtech.com" target="_blank" style={{ color: 'yellow' }}> Kanch Innovative Technologies</a>
        </div>
      </div>
      {/* Copyright */}

    </footer>
  </div>

  <section className="col-1" style={{ backgroundColor: 'rgb(15, 82, 126)' }}>
    <center>
    <img src={require('./img/sda.png')} width="100%" alt="SDA Logo" />
    </center>
  </section>
</div>

    </>
  );
}

export default ApplicationForm;
