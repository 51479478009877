import { useState, useEffect } from 'react'
import Axios from 'axios'
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBCheckbox,
    MDBIcon,
    MDBFile
  }
  from 'mdb-react-ui-kit';
  import Header from './Header';

const RegisterUsers = () => {
    const server_app_api_php = window.SERVER_APP_API_PHP
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [position, setPosition] = useState('');
  const [entity, setEntity] = useState('');
  const [committee, setCommittee] = useState('');
  const [office, setOffice] = useState('');

  const [entitiesDropDown, setEntitiesDropDown] = useState([])

  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData(this)

    formData.append('apiType', 'registerUsers')
    formData.append('name', name)
    formData.append('username', username)
    formData.append('position', position)
    formData.append('entity', entity)
    formData.append('committee', committee)
    formData.append('office', office)
   Axios({
      method: 'post',
      url: `${server_app_api_php}/SZBUmusicCampMeeting.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        //handle success
       alert(response.data)

       setName('');
       setUsername('');
       setPosition('');
       setEntity('');
       setCommittee('');
       setOffice('');
      })
      .catch(function (response) {
        //handle error
        alert("Error: Check Your Internet Connection")
      })
  };

  useEffect(() => {
    var formData = new FormData(this)
    formData.append('apiType', 'entitiesDropDown')
    Axios({
      method: 'post',
      url: `${server_app_api_php}/SZBUmusicCampMeeting.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setEntitiesDropDown(response.data)
      //alert(response.data);
    }) //.catch((err) => alert("ERROR : Check Your Network Connection"));
  }, [])

  return (
    <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>

<Header />
 

      <MDBRow>

      

        <MDBCol md='3' className='text-center text-md-start d-flex flex-column justify-content-center'>

          <h3 className="my-5 display-6 fw-bold ls-tight px-1" style={{color: 'hsl(218, 81%, 95%)'}}>            
            <span style={{color: 'green'}}>REGISTER USERS HERE</span>
          </h3>

          <p className='px-3 MDBColor primary' >
          Offcial Use Only. <br />
          No Unauthorized Access To This Page.
          </p>

        </MDBCol>

        <MDBCol md='9' className='position-relative'>

          <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
          <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

          

          <MDBCard className='my-5 bg-glass'>
            <MDBCardBody className='p-5'>
          

            <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-12 col-lg-4">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
          </div>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="form-group">
            <label htmlFor="username">Email</label>
            <input type="email" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} required />
          </div>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="form-group">
            <label htmlFor="position">Position</label>
            <select className="custom-select d-block w-100" value={position} onChange={(e) => setPosition(e.target.value)} required >
              <option value="">Select Position</option>
              <option value="Admin">Admin</option>
              <option value="Chairperson">Chairperson</option>
              <option value="Committee Secretary">Committee Secretary</option>
              <option value="Committee Member">Committee Member</option>
              <option value="SZBU Music Director">SZBU Music Director</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-4">
          <div className="form-group">
            <label htmlFor="entity">Entity</label>
          <select
        class='custom-select d-block w-100'
          id='validationCustom01'
          onChange={e => setEntity(e.target.value)}
        value={entity}
        required
        >
            <option value=''>Choose Entity</option>
            {entitiesDropDown.map(val => {
                          return (
                            <option value={val.entitiesID}>
                              {val.entity.toUpperCase()}
                            </option>
                          )
                        })}
        </select>
          </div>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="form-group">
            <label htmlFor="committee">Committee</label>
            <select className="custom-select d-block w-100" value={committee} onChange={(e) => setCommittee(e.target.value)}>
              <option value="">Select Committee</option>
              <option value="Finance and Registration">Finance and Registration</option>
              <option value="Security">Security</option>
              <option value="Publicity">Publicity</option>
            </select>
          </div>
        </div>
        <div className="col-md-12 col-lg-4">
          <div className="form-group">
            <label htmlFor="office">Office</label>
            <input type="text" className="form-control" value={office} onChange={(e) => setOffice(e.target.value)} />
          </div>
        </div>
      </div>
      <button type="submit" className="btn btn-primary col-12">Submit</button>
    </form>


             </MDBCardBody>
          </MDBCard>

        </MDBCol>

      </MDBRow>

    </MDBContainer>
  );
};

export default RegisterUsers;
